import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataCollectorService } from '../data-collector.service';


@Component({
  selector: 'app-video-news',
  templateUrl: './video-news.component.html',
  styleUrls: ['./video-news.component.css']
})
export class VideoNewsComponent implements OnInit {
  newsThirdSection: any;

  constructor(public service:DataCollectorService) {
    this.newsThirdSection=this.service.newsThirdSection;            
    var video_id =  this.ouTubeGetID(this.newsThirdSection.youtubeUrl);                
    this.newsThirdSection.embedVideoUrl="https://www.youtube.com/embed/"+video_id+"?autoplay=1";
    console.log(this.newsThirdSection.embedVideoUrl);
   }

  ngOnInit() {
  }

  ouTubeGetID(url){
    var VID_REGEX = /(?:youtube(?:-nocookie)?\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
    var ID=url.match(VID_REGEX)[1];
    return ID;
    // var ID = '';
    // url = url.replace(/(>|<)/gi,'').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    // if(url[2] !== undefined) {
    //   ID = url[2].split(/[^0-9a-z_\-]/i);
    //   ID = ID[0];
    // }
    // else {
    //   ID = url;
    // }
    //   return ID;
  }

  

}
