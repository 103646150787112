import { Component, OnInit } from '@angular/core';
import * as firebase from 'firebase';
import { Router } from '@angular/router';
import { DataCollectorService } from '../data-collector.service';


@Component({
  selector: 'app-news-center',
  templateUrl: './news-center.component.html',
  styleUrls: ['./news-center.component.css']
})
export class NewsCenterComponent implements OnInit {

  public allNews: Array<any> = [];
  public staticNews: Array<any> = [];

  constructor(public router:Router, public service:DataCollectorService) {
    this.getAllNews();
    this.getAllStaticNews();
   }

  ngOnInit() {
  }

  getAllNews() {
    firebase.database().ref('news/').once('value', (snapshot) => {
      var news = snapshot.val();
      for (var key in news) {
        var allnew = news[key];
        allnew.key = key;
        if(!allnew.status){
          this.allNews.push(allnew);
        }
       
      }
      this.allNews.reverse();
    })
  }

  getAllStaticNews() {
    firebase.database().ref('staticNews').once('value', (snapshot) => {
      var news = snapshot.val();
      for (var key in news) {
        var newNews = news[key];
        newNews.key = key;
        debugger;
        if (newNews.youtubeUrl) {
          var youtubeUrl = newNews.youtubeUrl;
          var sampleUrl = "http://www.youtube.com/watch?v=JcjoGn6FLwI&asdasd";
          var video_id =this.ouTubeGetID(youtubeUrl);
         
          // NSkKa6HYo6o
          newNews.videoImageUrl = "http://img.youtube.com/vi/" + video_id + "/0.jpg";

        }
        this.staticNews.push(newNews);
      }
      console.log(this.staticNews);
    })
  }

  toVideoNews(){
    
    this.service.newsThirdSection=this.staticNews[2];
    this.router.navigate(['/video-news'])
  }

  

  ouTubeGetID(url){
    var VID_REGEX = /(?:youtube(?:-nocookie)?\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
    var ID=url.match(VID_REGEX)[1];
    return ID;
    // var ID = '';
    // url = url.replace(/(>|<)/gi,'').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    // if(url[2] !== undefined) {
    //   ID = url[2].split(/[^0-9a-z_\-]/i);
    //   ID = ID[0];
    // }
    // else {
    //   ID = url;
    // }
    //   return ID;
  }

}
