import { Component, OnInit } from '@angular/core';
import * as firebase from 'firebase';

@Component({
  selector: 'app-players',
  templateUrl: './players.component.html',
  styleUrls: ['./players.component.css']
})
export class PlayersComponent implements OnInit {

  public allUsers: Array<any> = [];

  constructor() { 
    this.getAllActiveUsers();
  }

  ngOnInit() {
  }

  getAllActiveUsers() {
    firebase.database().ref().child('users/').once('value', (snaphot) => {
      var users = snaphot.val();
      for (var key in users) {
        var user = users[key];
        user.key = key;
        if (!user.isAdmin) {
          if(!user.status){
            this.allUsers.push(user);
          }
         
        }
      }
      this.allUsers.sort((a,b)=>{
        return a.jerseyNumber-b.jerseyNumber
      })
    })
  }

}
