import { Component, OnInit, NgZone, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as firebase from 'firebase';

@Component({
  selector: 'app-game-details',
  templateUrl: './game-details.component.html',
  styleUrls: ['./game-details.component.css']
})
export class GameDetailsComponent implements OnInit {

  public gameId: any;
  public game: any;
  public loading: any = false;
  public jerseyOne: any;
  public jerseyTwo: any;
  public jerseyThree: any;
  index: any;
  jerseys: any;

  constructor(public route: ActivatedRoute, public zone: NgZone, public cdr:ChangeDetectorRef) {
    this.route.params.subscribe((params) => {
      this.gameId = params['id'];
      this.getGameData();
    })
  }

  ngOnInit() {
  }
  toSaveJerseys(jersey) {
    var updates = {};
    var tempArray = [];
    if (jersey == 'jerseys1') {
      if(!this.jerseyOne){
        return alert("Add Jersey Number First!");
      }
      if (this.game.jerseyPlayers) {
        if (this.game.jerseyPlayers.jerseys1) {
          this.game.jerseyPlayers.jerseys1.push(this.jerseyOne);
        }
        else {
          tempArray.push(this.jerseyOne);
          this.game.jerseyPlayers.jerseys1 = tempArray;
        }
        updates['games/' + this.gameId + '/jerseyPlayers/jerseys1'] = this.game.jerseyPlayers.jerseys1;
        firebase.database().ref().update(updates).then(() => {
          this.jerseyOne="";
        })

      }
      else {
        if(!this.jerseyOne){
          return alert("Add Jersey Number First!");
        }
        tempArray.push(this.jerseyOne);
        this.game.jerseyPlayers = {
          jerseys1: tempArray
        }
        updates['games/' + this.gameId + '/jerseyPlayers'] = this.game.jerseyPlayers;
        firebase.database().ref().update(updates).then(() => {
          this.jerseyOne="";
        })
      }
    }
    else if (jersey == 'jerseys2') {
      if(!this.jerseyTwo){
        return alert("Add Jersey Number First!");
      }
      if (this.game.jerseyPlayers) {
        if (this.game.jerseyPlayers.jerseys2) {
          this.game.jerseyPlayers.jerseys2.push(this.jerseyTwo);
        }
        else {
          tempArray.push(this.jerseyTwo);
          this.game.jerseyPlayers.jerseys2 = tempArray;
        }
        updates['games/' + this.gameId + '/jerseyPlayers/jerseys2'] = this.game.jerseyPlayers.jerseys2;
        firebase.database().ref().update(updates).then(() => {
          this.jerseyTwo="";
        })

      }
      else {
        if(!this.jerseyTwo){
          return alert("Add Jersey Number First!");
        }
        tempArray.push(this.jerseyTwo);
        this.game.jerseyPlayers = {
          jerseys2: tempArray
        }
        updates['games/' + this.gameId + '/jerseyPlayers'] = this.game.jerseyPlayers;
        firebase.database().ref().update(updates).then(() => {
          this.jerseyTwo="";
        })
      }
    }
    else {
      if(!this.jerseyThree){
        return alert("Add Jersey Number First!");
      }
      if (this.game) {
        if (this.game.jerseyPlayers) {
          if (this.game.jerseyPlayers.jerseys3) {
            this.game.jerseyPlayers.jerseys3.push(this.jerseyThree);
          }
          else {
            tempArray.push(this.jerseyThree);
            this.game.jerseyPlayers.jerseys3 = tempArray;
          }
          updates['games/' + this.gameId + '/jerseyPlayers/jerseys3'] = this.game.jerseyPlayers.jerseys3;
          firebase.database().ref().update(updates).then(() => {
            this.jerseyThree="";
          })

        }
        else {
          if(!this.jerseyThree){
            return alert("Add Jersey Number First!");
          }
          tempArray.push(this.jerseyThree);
          this.game.jerseyPlayers = {
            jerseys3: tempArray
          }
          updates['games/' + this.gameId + '/jerseyPlayers'] = this.game.jerseyPlayers;
          firebase.database().ref().update(updates).then(() => {
            this.jerseyThree="";
          })

        }
      }

    }


  }

  toDelete(i, jersey) {
    this.jerseys = jersey;
    this.index = i;
  }

  deleteJersey() {
    var updates = {};
    if (this.jerseys == 'jerseys1') {
      this.game.jerseyPlayers.jerseys1.splice(this.index,1);
      updates['games/' + this.gameId + '/jerseyPlayers/jerseys1'] = this.game.jerseyPlayers.jerseys1;
    }
    else if (this.jerseys == 'jerseys2') {
      this.game.jerseyPlayers.jerseys2.splice(this.index,1);
      updates['games/' + this.gameId + '/jerseyPlayers/jerseys2'] = this.game.jerseyPlayers.jerseys2;
    }
    else {
      this.game.jerseyPlayers.jerseys3.splice(this.index,1);
      updates['games/' + this.gameId + '/jerseyPlayers/jerseys3'] = this.game.jerseyPlayers.jerseys3;
    }
    firebase.database().ref().update(updates).then(() => {
    })
  }

  getGameData() {
    this.loading = true;
    firebase.database().ref().child('games/' + this.gameId).once('value', (snapshot) => {
      this.game = snapshot.val();
      if (this.game.team) {
        for (var i = 0; i < this.game.team.length; i++) {
          this.getUserInfo(this.game, i);   
        }
      }
      if (this.game.notJoin) {
        for (var j = 0; j < this.game.notJoin.length; j++) {
          this.getUserInfoNotJoin(this.game, j);
        }
      }
      
      
      console.log(this.game);
      this.loading = false;
    })
  }

  getUserInfo(game, i) {
    firebase.database().ref('users/' + this.game.team[i]).once('value', (snapshot) => {
      var userData = snapshot.val();
      if(userData){
        this.zone.run(() => {
          game.team[i] = userData ;
        })
      }
      else{
        debugger;
        this.zone.run(() => {
        this.game.team.splice(i,1);
        this.cdr.markForCheck();
        this.cdr.detectChanges();
        })
    }
    this.game.team.sort((a,b)=>{
      return a.jerseyNumber-b.jerseyNumber;
    })
 
    })
  }
  getUserInfoNotJoin(game, j) {
    firebase.database().ref('users/' + this.game.notJoin[j]).once('value', (snapshot) => {
      var userData = snapshot.val();
      this.zone.run(() => {
        game.notJoin[j] = userData;
      })
      this.game.notJoin.sort((a,b)=>{
        return a.jerseyNumber-b.jerseyNumber;
      })
    })
  }

}
