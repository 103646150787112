import { Component, OnInit } from '@angular/core';
import * as firebase from 'firebase';

@Component({
  selector: 'app-all-games',
  templateUrl: './all-games.component.html',
  styleUrls: ['./all-games.component.css']
})
export class AllGamesComponent implements OnInit {

  allGames:Array<any>=[];
  loading:any=false;
  index:any;

  constructor() {
   
   }

  ngOnInit() {
    this.getAllGames();
  }
  
  getAllGames(){
    firebase.database().ref('games/').once('value',(snapshot)=>{
      var games=snapshot.val();
      for (var key in games){
        var game=games[key];
        game.key=key;
        var dateObj = new Date(game.gameDate + ' ' + game.gameTime);
        game.dateObj=dateObj;
        this.allGames.push(game);
      }
      this.allGames.sort((a, b) => {
        return Number(a.dateObj) - Number(b.dateObj);
      });

    })
  }

  toDelete(i){
    this.index=i;
  }

  deleteGame(){
    var updates={};
    updates['games/'+this.allGames[this.index].key]=[];
    firebase.database().ref().update(updates).then(()=>{
      this.allGames.splice(this.index,1);
    })

  }

}
