import { Component, OnInit } from '@angular/core';
import * as firebase from 'firebase';



@Component({
  selector: 'app-polls',
  templateUrl: './polls.component.html',
  styleUrls: ['./polls.component.css']
})
export class PollsComponent implements OnInit {
  allPolls: Array<any> = [];
  public resetIndex:any;

  constructor() {
    this.getAllPolls();
  }

  ngOnInit() {
  }

  getAllPolls() {
    firebase.database().ref('polls').once('value', (snapshot) => {
      var polls = snapshot.val();
      for (var key in polls) {
        var poll = polls[key];
        poll.key = key;
        this.allPolls.push(poll);
      }
    })
  }

  toReset(i){
    this.resetIndex=i;
  }

  resetVoting(){
    var updates={};
    for(var j=0;j<this.allPolls[this.resetIndex].options.length;j++){
    if(this.allPolls[this.resetIndex].options[j].votes){
      this.allPolls[this.resetIndex].options[j].votes=[];
    }
    }
    updates['polls/'+this.allPolls[this.resetIndex].key+'/options']=this.allPolls[this.resetIndex].options;
    updates['polls/'+this.allPolls[this.resetIndex].key+'/votes']=[];
    firebase.database().ref().update(updates).then(()=>{
      alert("Poll Reset!");
    })


  }



}
