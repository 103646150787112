import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as firebase from 'firebase';

@Component({
  selector: 'app-news-details',
  templateUrl: './news-details.component.html',
  styleUrls: ['./news-details.component.css']
})
export class NewsDetailsComponent implements OnInit {

  public newsId:any;
  public newsData:any;

  constructor(public route:ActivatedRoute) {
    this.route.params.subscribe((params)=>{
      this.newsId=params['id'];
      this.getNewsData();
    })
   }

  ngOnInit() {
  }

  getNewsData(){
    firebase.database().ref().child('news/'+this.newsId).once('value',(snapshot)=>{
      this.newsData=snapshot.val();
  })
}

}
