import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { GameComponent } from './game/game.component';
import { GameDetailComponent } from './game-detail/game-detail.component';
import { FooterComponent } from './footer/footer.component';
import { AdminHeaderComponent } from './admin-header/admin-header.component';
import { AllUsersComponent } from './all-users/all-users.component';
import { AllGamesComponent } from './all-games/all-games.component';
import * as firebase from 'firebase';
import { FormsModule } from '@angular/forms';
import { NewGameComponent } from './new-game/new-game.component';
import { GameDetailsComponent } from './game-details/game-details.component';
import { NgxLoadingModule } from 'ngx-loading';
import { CreateUserComponent } from './create-user/create-user.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { PollsComponent } from './polls/polls.component';
import { EditPollComponent } from './edit-poll/edit-poll.component';
import { DatePipe } from '@angular/common';
import { StatsComponent } from './stats/stats.component';
import { SliderImagesComponent } from './slider-images/slider-images.component';
import { NewsComponent } from './news/news.component';
import { AddNewsComponent } from './add-news/add-news.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { StaticNewsComponent } from './static-news/static-news.component';
import { NewsCenterComponent } from './news-center/news-center.component';
import { NewsDetailsComponent } from './news-details/news-details.component';
import { VideoNewsComponent } from './video-news/video-news.component';
import { SafePipe } from './safe.pipe';
import { SliderComponent } from './slider/slider.component';
import { AllStatsComponent } from './all-stats/all-stats.component';
import { PlayersComponent } from './players/players.component';
import { OrderByPipe } from './order-by.pipe';
import { AboutAdminComponent } from './about-admin/about-admin.component';
import { AboutGmtComponent } from './about-gmt/about-gmt.component';


var firebaseConfig = {
  apiKey: "AIzaSyDcDP9RDPAG5rH0tDJ0iH_o-Drk3AGKAkc",
  authDomain: "gmt-soccer.firebaseapp.com",
  databaseURL: "https://gmt-soccer.firebaseio.com",
  projectId: "gmt-soccer",
  storageBucket: "gmt-soccer.appspot.com",
  messagingSenderId: "242701235716",
  appId: "1:242701235716:web:daf71d251e87c248d3e751",
  measurementId: "G-2H8FMB0E92"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const appRoutes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'all-users', component: AllUsersComponent },
  { path: 'all-games', component: AllGamesComponent },
  { path: 'game', component: GameComponent },
  { path: 'game-detail/:id', component: GameDetailComponent },
  { path: 'new-game', component: NewGameComponent },
  { path: 'new-game/:id', component: NewGameComponent },
  { path: 'game-details/:id', component: GameDetailsComponent },
  { path: 'create-user', component: CreateUserComponent },
  { path: 'polls', component: PollsComponent },
  { path: 'edit-poll/:id', component: EditPollComponent },
  { path: 'stats', component: AllStatsComponent },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'slider-images', component: SliderImagesComponent },
  { path: 'add-news', component: AddNewsComponent },
  { path: 'add-news/:id', component: AddNewsComponent },
  { path: 'static-news', component: StaticNewsComponent },
  { path: 'news-center', component: NewsCenterComponent },
  { path: 'news-details/:id', component: NewsDetailsComponent },
  { path: 'video-news', component: VideoNewsComponent },
  { path: 'news', component: NewsComponent },
  { path: 'players', component: PlayersComponent },
  { path: 'about-gmt', component: AboutGmtComponent },
  { path: 'about-admin', component: AboutAdminComponent },

];
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    GameComponent,
    GameDetailComponent,
    FooterComponent,
    AdminHeaderComponent,
    AllUsersComponent,
    AllGamesComponent,
    NewGameComponent,
    GameDetailsComponent,
    CreateUserComponent,
    PollsComponent,
    EditPollComponent,
    StatsComponent,
    SliderImagesComponent,
    NewsComponent,
    AddNewsComponent,
    StaticNewsComponent,
    NewsCenterComponent,
    NewsDetailsComponent,
    VideoNewsComponent,
    SafePipe,
    SliderComponent,
    AllStatsComponent,
    PlayersComponent,
    OrderByPipe,
    AboutAdminComponent,
    AboutGmtComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ImageCropperModule,
    CKEditorModule,
    NgxLoadingModule.forRoot({}),
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: false } // <-- debugging purposes only
    ),
  ],
  providers: [
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
