import { Component, OnInit } from '@angular/core';
import * as firebase from 'firebase';

@Component({
  selector: 'app-slider-images',
  templateUrl: './slider-images.component.html',
  styleUrls: ['./slider-images.component.css']
})
export class SliderImagesComponent implements OnInit {

  public sliderImages: Array<any> = [];
  public sliderImage: any;
  public loading: any = false;
  public slider:Array<any>=[];

  constructor() {
    this.getSliderImages();
  }

  ngOnInit() {
  }

  getSliderImages() {
    firebase.database().ref('sliderImages').once('value', (snapshot) => {
      var images = snapshot.val();
      for (var key in images) {
        this.sliderImages = images[key];
      }

    })
  }

  toChangeStatusEnabled(i){
    var updates = {};
    updates['sliderImages/' + 'slideImages/'+i+'/status'] = '';
    firebase.database().ref().update(updates).then(() => {
     this.sliderImages[i].status="";
    })
  }

  toChangeStatusDisabled(i){
    var updates = {};
    updates['sliderImages/' + 'slideImages/'+i+'/status'] = 'disabled';
    firebase.database().ref().update(updates).then(() => {
     this.sliderImages[i].status="disabled";
    })

  }


  onChangeFile(event: EventTarget) {
    this.loading = true;
    let eventObj: MSInputMethodContext = <MSInputMethodContext>event;
    let target: HTMLInputElement = <HTMLInputElement>eventObj.target;
    let files: FileList = target.files;
    this.sliderImage = files[0];
    this.uploadFileToStorage();
  }

  uploadFileToStorage() {

    var self = this;
    let storageRef = firebase.storage().ref();

    var metadata = {
      contentType: 'image/jpeg/png'
    };
    const filename = Math.floor(Date.now() / 1000);
    var uploadTask = storageRef.child(`sliderImages/${filename}.jpg`).put(self.sliderImage, metadata);
    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
      (snapshot) => {

      }, (error) => {
        alert(error.message);
      }, () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          var data={
            image:downloadURL,
            status:''
          }
          self.sliderImages.push(data);
          self.saveImageToFirebase();
        });
      });
  }

  saveImageToFirebase() {
    var updates = {};

    updates['sliderImages/' + 'slideImages'] = this.sliderImages;
    firebase.database().ref().update(updates).then(() => {
      this.loading=false;
      alert("Image Added!");
    })
  }

  toRemoveImage(i) {
    this.sliderImages.splice(i, 1);
    var updates = {};
    updates['sliderImages/' + 'slideImages'] = this.sliderImages;
    firebase.database().ref().update(updates).then(() => {
      alert("Image Removed!");
    })

  }


}
