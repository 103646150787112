import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as firebase from 'firebase';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-about-admin',
  templateUrl: './about-admin.component.html',
  styleUrls: ['./about-admin.component.css']
})
export class AboutAdminComponent implements OnInit {

  public data: any = {
    aboutUsDetail: ''
  };
  public loading: any = false;
  public newFile: boolean = false;
  public aboutUsImg: any;
  public imageUrl: any;
  public Editor = ClassicEditor;
  htmlEditorConfig = {
    mediaEmbed: {
        previewsInData: true
    }
}

  constructor(
    public router: Router) {

  }

  ngOnInit() {
    this.getNewsData();
  }


  getNewsData() {
    var self = this;
    self.loading = true;
    firebase.database().ref().child('abouts/aboutUsData')
      .once('value', (snapshot) => {
        self.data = snapshot.val();
        self.imageUrl = self.data.aboutUsBanner;
        self.loading = false;
      })
  }


  onChangeFile(event: EventTarget) {
    let eventObj: MSInputMethodContext = <MSInputMethodContext>event;
    let target: HTMLInputElement = <HTMLInputElement>eventObj.target;
    let files: FileList = target.files;
    this.aboutUsImg = files[0];
    var reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.newFile = true;
      this.imageUrl = reader.result;
    }
  }


  uploadImg() {
    var self = this;
    let storageRef = firebase.storage().ref();
    var metadata = {
      contentType: 'image/jpeg/png'
    };
    const filename = Math.floor(Date.now() / 1000) + '.jpg';
    storageRef.child('newsImages/' + filename).put(self.aboutUsImg, metadata)
      .then((snapshot) => {
        snapshot.ref.getDownloadURL().then((downloadURL) => {
          self.data.aboutUsBanner = downloadURL;
          self.saveDataFirebase();
        });
      });
  }


  updateData() {
    if (!this.data.aboutUsTitle || !this.data.aboutUsDetail) {
      return alert("Fill all the Fields");
    }
    if (this.newFile) {
      this.loading = true;
      this.uploadImg();
    }
    else {
      this.loading = true;
      this.saveDataFirebase();
    }
  }


  saveDataFirebase() {
    var updates = {};
    updates['abouts/' + 'aboutUsData'] = this.data;
    firebase.database().ref().update(updates).then(() => {
      alert("Data Updated Successfully !");
      this.loading = false;
    })
  }


}
