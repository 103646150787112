import { Component, OnInit } from '@angular/core';
import * as firebase from 'firebase';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-new-game',
  templateUrl: './new-game.component.html',
  styleUrls: ['./new-game.component.css']
})
export class NewGameComponent implements OnInit {

  public game:any={};
  public loading:any=false;
  public gameId:any;
  detailImage: File;
  detailImageUrl: string | ArrayBuffer;
  summaryImage: File;
  summaryImageUrl: string | ArrayBuffer;

  constructor(public router:Router, public route:ActivatedRoute) { 

    this.route.params.subscribe((params)=>{
      this.gameId=params['id'];
      if(this.gameId){
        this.getGameData();
      }
    })
  }

  ngOnInit() {
  }

  getGameData(){
    this.loading=true;
    firebase.database().ref().child('games/'+this.gameId).once('value',(snapshot)=>{
      this.game=snapshot.val();
      this.summaryImageUrl=this.game.summaryImage;
      this.detailImageUrl=this.game.detailImage;
      this.loading=false;
    })
  }

  onChangeFile(event: EventTarget) {
    let eventObj: MSInputMethodContext = <MSInputMethodContext>event;
    let target: HTMLInputElement = <HTMLInputElement>eventObj.target;
    let files: FileList = target.files;
    this.detailImage = files[0];
    var reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.detailImageUrl = reader.result;
    }
  }
  onChangeFileSummary(event: EventTarget) {
    let eventObj: MSInputMethodContext = <MSInputMethodContext>event;
    let target: HTMLInputElement = <HTMLInputElement>eventObj.target;
    let files: FileList = target.files;
    this.summaryImage = files[0];
    var reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.summaryImageUrl = reader.result;
    }
  }

  uploadImg() {

    var self = this;
    let storageRef = firebase.storage().ref();

    var metadata = {
      contentType: 'image/jpeg/png'
    };
    const filename = Math.floor(Date.now() / 1000);
    var uploadTask = storageRef.child(`gameImages/${filename}.jpg`).put(self.detailImage, metadata);
    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
      (snapshot) => {

      }, (error) => {
        alert(error.message);
      }, () => {
        uploadTask.snapshot.ref.getDownloadURL().then( (downloadURL)=> {
          self.game.detailImage = downloadURL;
          if(this.summaryImage){
          this.uploadSummaryImage();
          }
          else{
           this.saveDataFirebase();
          }
        });
      });
  }

  uploadSummaryImage(){
    var self = this;
    let storageRef = firebase.storage().ref();

    var metadata = {
      contentType: 'image/jpeg/png'
    };
    const filename = Math.floor(Date.now() / 1000);
    var uploadTask = storageRef.child(`gameImages/${filename}.jpg`).put(self.summaryImage, metadata);
    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
      (snapshot) => {

      }, (error) => {
        alert(error.message);
      }, () => {
        uploadTask.snapshot.ref.getDownloadURL().then( (downloadURL)=> {
          self.game.summaryImage = downloadURL;
          this.saveDataFirebase();
        });
      });

  }



  createGame(){
   if(!this.game.gameName || !this.game.gameDate || !this.game.gameTime || !this.game.location || !this.game.players){
     return alert("Fill all the Fields");
   }
   debugger;
   if((!this.game.jerseys1 && !this.game.jerseys2 && !this.game.jerseys3) || (!this.game.jerseys1 && !this.game.jerseys2) || (!this.game.jerseys1 && !this.game.jerseys3) ||(!this.game.jerseys2 && !this.game.jerseys3)){
     return alert("Select atleast two jerseys");
   }
   if(this.detailImage){
    this.loading=true;
     this.uploadImg();
   }
   else if(this.summaryImage){
    this.loading=true;
     this.uploadSummaryImage();
   }
   else{
    this.loading=true;
     this.saveDataFirebase();
   }
   
}

saveDataFirebase(){
  
   var updates={};
   if(this.gameId){
   updates['games/'+this.gameId]=this.game;
   firebase.database().ref().update(updates).then(()=>{
     alert("Game Updated Successfully !");
     this.router.navigate(['/all-games']);
     this.loading=false;
   })
   }
   else{
   var postKey=firebase.database().ref('games/').push().key;
   updates['games/'+postKey]=this.game;
   firebase.database().ref().update(updates).then(()=>{
     alert("Game Added Successfully !");
     this.router.navigate(['/all-games']);
     this.loading=false;
   })
  }

}

}
