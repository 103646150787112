import { Component, OnInit, NgZone } from '@angular/core';
import * as moment from 'moment';
import * as firebase from 'firebase';


@Component({
  selector: 'app-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.css']
})
export class GameComponent implements OnInit {

  public date = new Date();
  public currentDate: any = new Date();
  public allGames: Array<any> = [];
  public isClient: any = false;
  public tempGame: any;

  constructor(public zone: NgZone) {
    if (localStorage.getItem("userType") == 'client') {
      this.isClient = true;
    }
    this.getAllGames();
  }

  ngOnInit() {
    this.date = new Date(this.date.setDate(1));
  }

  getAllGames() {
    this.allGames = [];
    var dateObj;
    var date;
    var dateTime;
    firebase.database().ref().child('games/').once('value', (snapshot) => {
      var games = snapshot.val();
      for (var key in games) {
        var game = games[key];
        game.key = key;
        if (game.team) {
          if (game.team.includes(localStorage.getItem('uid'))) {
            game.join = true;
          }
        }
        dateObj = moment(game.gameDate + ' ' + game.gameTime, "YYYY-MM-DD HH:mm").toDate();
        game.dateObj = dateObj;
        dateTime = Number(dateObj);
        date = Number(new Date());
         debugger;
        if (86400000 < (dateTime - date)) {
          game.update = true;
        }
        else{ 
          if(game.team){
          if(game.team.includes(localStorage.getItem('uid'))){
            debugger;
            console.log(dateObj.getDate());
            console.log(new Date().getDate());
            debugger;
            if(dateObj.getDate()>new Date().getDate()){
              game.update=true;
            }
          }
          }
          if(game.notJoin){
          if(game.notJoin.includes(localStorage.getItem('uid'))){
            if(dateObj.getDate()>new Date().getDate()){
              game.update=true;
            }
          }
        }

      
      }
  
        if (dateObj.getMonth() == this.date.getMonth() && dateObj.getFullYear() == this.date.getFullYear()) {
          this.allGames.push(game);
        }
      }
      this.allGames.sort((a, b) => {
        return Number(a.dateObj) - Number(b.dateObj);
      });
      console.log(this.allGames)
    })
  }

  joinGameExtraStep(game, index) {
    if (game.join) {
      this.joinGame(game);
    } else {
      var self = this;
      self.zone.run(() => {
        self.allGames[index].join = false;
        self.tempGame = self.allGames[index];
      });
      let modal: HTMLElement = document.getElementById("modalBtn") as HTMLElement;
      modal.click();
    }
  }

  confirmNotJoin() {
    this.zone.run(() => {
      this.tempGame.join = false;
      this.joinGame(this.tempGame);
    });
  }

  reverseToggle() {
    this.tempGame.join = true;
  }

  joinGame(game) {
    debugger;
    var team = [];
    var notJoin = [];
    var updates = {};
    if (game.team) {
      team = game.team;
    }
    if (game.notJoin) {
      notJoin = game.notJoin;
    }
    if (!game.join) {
      var index = team.indexOf(localStorage.getItem('uid'));
      if (index !== -1) {
        team.splice(index, 1);
      }
      if(!notJoin.includes(localStorage.getItem('uid'))){
        notJoin.push(localStorage.getItem('uid'));
      }
     
      updates['games/' + game.key + '/team'] = team;
      updates['games/' + game.key + '/notJoin'] = notJoin;
      firebase.database().ref().update(updates).then(() => {
      });
    } else {
      if(!team.includes(localStorage.getItem('uid'))){
        team.push(localStorage.getItem('uid'));
      }
      
      var index = notJoin.indexOf(localStorage.getItem('uid'));
      if (index !== -1) {
        notJoin.splice(index, 1);
      }
      updates['games/' + game.key + '/team'] = team;
      updates['games/' + game.key + '/notJoin'] = notJoin;
      firebase.database().ref().update(updates).then(() => {
      });
    }
  }

  toNextMonth() {
    debugger;
    this.date = new Date(this.date.setMonth(this.date.getMonth() + 1));
    this.date = moment(this.date).toDate();
    this.getAllGames();
  }

  toPreviousMonth() {
    if (Number(this.currentDate) > Number(this.date)) {
      return;
    }
    this.date = new Date(this.date.setMonth(this.date.getMonth() - 1));
    this.date = moment(this.date).toDate();
    this.getAllGames();
  }

  dismissModal(e: any, modalId, closingId) {
    debugger;
    if (e.srcElement.id) {
      var el = document.getElementById(closingId);
      if (el) {
        el.click();
      }
    }
  }
}
