import { Component, OnInit } from '@angular/core';
import * as firebase from 'firebase';
import { Router } from '@angular/router';
import { DataCollectorService } from '../data-collector.service';

@Component({
  selector: 'app-all-users',
  templateUrl: './all-users.component.html',
  styleUrls: ['./all-users.component.css']
})
export class AllUsersComponent implements OnInit {

  public uid: any;
  public userImage: any;
  public imageUrl: any;
  public user: any = {
    timestamp: Number(new Date())
  }
  public loading: any = false;
  public allUsers: Array<any> = [];
  public index: any;

  constructor(public router: Router,
    public dataCollector: DataCollectorService) {
  }

  ngOnInit() {
    this.getAllUsers();
  }

  getAllUsers() {
    this.loading = true;
    firebase.database().ref().child('users/').once('value', (snaphot) => {
      var users = snaphot.val();
      for (var key in users) {
        var user = users[key];
        user.key = key;
        if (!user.isAdmin) {
          this.allUsers.push(user);
        }
        this.loading = false;
      }
      this.sortViaJerseyNumber();
    })
  }

  sortViaJerseyNumber(){
    this.allUsers.sort((a,b)=>{
      return a.jerseyNumber-b.jerseyNumber;
    })
  }

  toDelete(i){
    this.index=i;
  }

  deleteUser(){
    var deleteUser = firebase.functions().httpsCallable("deleteUser");
    this.loading=true;
    deleteUser({
     uid:this.allUsers[this.index].uid
    }).then((result) => {
      if (result) {
       this.removeUserFromAllGames(this.index);
      }
      else {
        alert(result.data.message);
      }
    }, function (error) {
      alert(error);
    });
  }


  removeUserFromAllGames(i){
    firebase.database().ref('games/').once('value',(snapshot)=>{
      var games=snapshot.val();
      for (var key in games){
        var game=games[key];
        game.key=key;
        var updates={};
        if(game.team){
        if (game.team.includes(this.allUsers[i].uid)) {
          var team = game.team;
          var index=team.indexOf(this.allUsers[i].uid);
          if(index !== -1){
            team.splice(index,1);
          }
          updates['games/'+game.key+'/team']=team;
          firebase.database().ref().update(updates);
        }
      }
      if(game.notJoin){
        if (game.notJoin.includes(this.allUsers[i].uid)) {
          var notJoin = game.notJoin;
          var index=notJoin.indexOf(this.allUsers[i].uid);
          if(index !== -1){
            notJoin.splice(index,1);
          }
          updates['games/'+game.key+'/notJoin']=notJoin;
          firebase.database().ref().update(updates);
        }
      }
    }
    this.removeUserFromDatabase(this.index);
      
    })

  }



  removeUserFromDatabase(i){
    var updates={};
    updates['users/'+this.allUsers[i].uid]=[];
    this.allUsers.splice(i,1);
    this.loading=false;
    firebase.database().ref().update(updates).then(()=>{
      alert("User deleted Successfully!");
    })
  }

  toActive(user) {
    var updates = {};
    updates['users/' + user.uid + '/status'] = [];
    firebase.database().ref().update(updates).then(() => {
      user.status = "";
    })
  }

  toRestrict(user) {
    var updates = {};
    updates['users/' + user.uid + '/status'] = 'restrict';
    firebase.database().ref().update(updates).then(() => {
      user.status = "restrict";
    });
  }

  onChangeFile(event: EventTarget) {
    let eventObj: MSInputMethodContext = <MSInputMethodContext>event;
    let target: HTMLInputElement = <HTMLInputElement>eventObj.target;
    let files: FileList = target.files;
    this.userImage = files[0];
    var reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imageUrl = reader.result;
    }
  }

  addUser() {
    if (!this.user.name || !this.user.phone || !this.user.nickName || !this.user.password || !this.user.confirmPassword || !this.user.jerseyNumber || !this.user.position || !this.user.remark) {
      return alert("Fill all Fields!");
    }
    if (this.user.password != this.user.confirmPassword) {
      return alert('Password Mismatched !');
    }
    this.loading = true;
    var addUser = firebase.functions().httpsCallable("createUser");
    addUser({
      name: this.user.name,
      email: `gmt${this.user.phone}@email.com`,
      password: this.user.password,

    }).then((result) => {
      if (result.data.status == "200") {
        this.uid = result.data.data.uid;
        if (this.uid) {
          if (this.userImage) {
            this.uploadUserImg(this.uid);
          }
          else {
            this.saveDataFirebase(this.uid);
          }
        }
      }
      else {
        alert(result.data.message);
      }
    }, (error) => {
      alert(error);
    });
  }

  uploadUserImg(uid) {

    var self = this;
    let storageRef = firebase.storage().ref();

    var metadata = {
      contentType: 'image/jpeg/png'
    };
    const filename = Math.floor(Date.now() / 1000);
    var uploadTask = storageRef.child(`profileImages/${filename}.jpg`).put(self.userImage, metadata);
    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
      (snapshot) => {

      }, (error) => {
        alert(error.message);
      }, () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          self.user.profileUrl = downloadURL;
          self.saveDataFirebase(uid);
        });
      });
  }


  saveDataFirebase(uid) {
    var self = this;
    var id = document.getElementById('newUser');
    this.user.uid = uid;
    delete this.user.password;
    delete this.user.confirmPassword;
    var updates = {};
    updates['/users/' + uid] = this.user;
    firebase.database().ref().update(updates).then(() => {
      alert("Account Created Successfully");
      id.click();
      this.allUsers.push(this.user);
      this.loading = false;
      this.router.navigate(['/all-users']);
      this.user = {};
      this.imageUrl = "";
    });
  }

  editUser(userObj) {
    this.dataCollector.userEditObj = userObj;
    this.dataCollector.isEdit = true;
    this.router.navigate(['/create-user']);
  }

  goToCreateUser() {
    this.router.navigate(['/create-user']);
  }
}
