import { Component, OnInit } from '@angular/core';
import * as firebase from 'firebase';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-static-news',
  templateUrl: './static-news.component.html',
  styleUrls: ['./static-news.component.css']
})
export class StaticNewsComponent implements OnInit {

  public staticNews:Array<any>=[];
  public loading:any =false;
  public istSectionImage:any;
  public Editor = ClassicEditor;
  secondSectionImage: File;
  htmlEditorConfig = {
    mediaEmbed: {
        previewsInData: true
    }
}



  constructor() {
    this.getAllStaticNews();
   }

  ngOnInit() {
  }

  getAllStaticNews(){
    firebase.database().ref('staticNews').once('value',(snapshot)=>{
      var news=snapshot.val();
      for(var key in news){
        var newNews=news[key];
        newNews.key=key;
        this.staticNews.push(newNews);
      }
      console.log(this.staticNews);
    })
  }
  toEnabledNews(){
    var updates={};
    var pathKey=this.staticNews[2].key;
    updates['staticNews/'+pathKey+'/status']='' ;
    firebase.database().ref().update(updates).then(()=>{
      this.staticNews[2].status="";
    })
  }

  toDisabledNews(){
    var updates={};
    var pathKey=this.staticNews[2].key;
    updates['staticNews/'+pathKey+'/status']='disabled' ;
    firebase.database().ref().update(updates).then(()=>{
      this.staticNews[2].status="disabled";
    })

  }

  onChangeFileFisrtSection(event: EventTarget) {
    if(!this.staticNews[0].images){
      this.staticNews[0].images=[];
    }
    this.loading = true;
    let eventObj: MSInputMethodContext = <MSInputMethodContext>event;
    let target: HTMLInputElement = <HTMLInputElement>eventObj.target;
    let files: FileList = target.files;
    this.istSectionImage = files[0];
    this.uploadFileToStorage();
  }

  uploadFileToStorage() {

    var self = this;
    let storageRef = firebase.storage().ref();

    var metadata = {
      contentType: 'image/jpeg/png'
    };
    const filename = Math.floor(Date.now() / 1000);
    var uploadTask = storageRef.child(`newsImages/${filename}.jpg`).put(self.istSectionImage, metadata);
    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
      (snapshot) => {

      }, (error) => {
        alert(error.message);
      }, () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          self.staticNews[0].images.push(downloadURL);
          self.saveImageToFirebase();
        });
      });
  }

  saveImageToFirebase() {
    var updates = {};
    var postKey=this.staticNews[0].key;
    this.staticNews[0].key=null;
    updates['staticNews/' +postKey+'/images' ] = this.staticNews[0].images;
    firebase.database().ref().update(updates).then(() => {
      this.staticNews[0].key=postKey;
      this.loading=false;
      alert("Image Added!");
    })
  }

  toDeleteFromFirstSection(i){
    var updates={};
    this.staticNews[0].images.splice(i,1);
    var pathKey=this.staticNews[0].key;
    this.staticNews[0].key=null;

    updates['staticNews/'+pathKey+'/images']=this.staticNews[0].images;
    firebase.database().ref().update(updates).then(()=>{
      this.staticNews[0].key=pathKey;
      alert("Image Removed")
    })
  }

  

  onChangeFileSecondSection(event: EventTarget) {
    this.loading = true;
    let eventObj: MSInputMethodContext = <MSInputMethodContext>event;
    let target: HTMLInputElement = <HTMLInputElement>eventObj.target;
    let files: FileList = target.files;
    this.secondSectionImage = files[0];
    debugger;
    this.uploadFileToStorageForSecond();
  }

  uploadFileToStorageForSecond(){
    var self = this;
    let storageRef = firebase.storage().ref();

    var metadata = {
      contentType: 'image/jpeg/png'
    };
    const filename = Math.floor(Date.now() / 1000);
    var uploadTask = storageRef.child(`newsImages/${filename}.jpg`).put(self.secondSectionImage, metadata);
    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
      (snapshot) => {
        debugger;
      }, (error) => {
        alert(error.message);
      }, () => {
        debugger;
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          self.staticNews[1].weekUpdate=downloadURL;
          self.saveImageToFirebaseForSecondSection();
        });
      });
  }

  saveImageToFirebaseForSecondSection() {
    var updates = {};
    var postKey=this.staticNews[1].key;
    this.staticNews[1].key=null;
    updates['staticNews/' +postKey+'/weekUpdate' ] = this.staticNews[1].weekUpdate;
    firebase.database().ref().update(updates).then(() => {
      this.staticNews[1].key=postKey;
      this.loading=false;
      alert("Image Added!");
    })
  }

  toDeleteFromSecondSection(i){
    var updates={};
    var pathKey=this.staticNews[1].key;
    this.staticNews[1].key=null;

    updates['staticNews/'+pathKey+'/weekUpdate']=[];
    firebase.database().ref().update(updates).then(()=>{
      this.staticNews[1].weekUpdate="";
      this.staticNews[1].key=pathKey;
      alert("Image Removed")
    })
  }

  toSaveDataThirdSection(){
    var updates={};
    var pathKey=this.staticNews[2].key;
    this.staticNews[2].key=null;
    updates['staticNews/'+pathKey+'/newsTitle']=this.staticNews[2].newsTitle ;
    updates['staticNews/'+pathKey+'/youtubeUrl']=this.staticNews[2].youtubeUrl ;
    updates['staticNews/'+pathKey+'/timestamp']=Number(new Date());
    firebase.database().ref().update(updates).then(()=>{
      this.staticNews[2].key=pathKey;
      alert("Data Saved")
    })

  }

}
