import { Component, OnInit } from '@angular/core';
import * as firebase from 'firebase';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {
   
  public allNews:Array<any>=[];
  public index:any;
  constructor() {
    this.getAllNews();
   }

  ngOnInit() {
  }

  getAllNews(){
    firebase.database().ref('news/').once('value',(snapshot)=>{
      var news=snapshot.val();
      for (var key in news){
        var allnew=news[key];
        allnew.key=key;
        this.allNews.push(allnew);
      }
      this.allNews.reverse();
    })
  }

  toDelete(i){
    this.index=i;
  }

  deleteNews(){
    var updates={};
    updates['news/'+this.allNews[this.index].key]=[];
    firebase.database().ref().update(updates).then(()=>{
      this.allNews.splice(this.index,1);
    })

  }

  toChangeStatusEnable(i){
    var updates={};
    updates['news/'+this.allNews[i].key+'/status']="";
    firebase.database().ref().update(updates).then(()=>{
      this.allNews[i].status="";
    })
  }
  toChangeStatusDisable(i){
    var updates={};
    updates['news/'+this.allNews[i].key+'/status']="disabled";
    firebase.database().ref().update(updates).then(()=>{
      this.allNews[i].status="disabled";
    })
  }

}
