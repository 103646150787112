import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as firebase from 'firebase';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-add-news',
  templateUrl: './add-news.component.html',
  styleUrls: ['./add-news.component.css']
})
export class AddNewsComponent implements OnInit {

  public news:any={
    newsDetail:'',
    timestamp:Number(new Date())
  };
  public newsId:any;
  public loading:any=false;
  public newsImage:any;
  public newsImageUrl:any;
  public Editor = ClassicEditor;
  htmlEditorConfig = {
    mediaEmbed: {
        previewsInData: true
    }
}

  constructor(public route:ActivatedRoute, public router:Router) {
    this.route.params.subscribe((params)=>{
      this.newsId=params['id'];
      if(this.newsId){
        this.getNewsData();
      }
    })
   }

  ngOnInit() {
  }

  getNewsData(){
    this.loading=true;
    firebase.database().ref().child('news/'+this.newsId).once('value',(snapshot)=>{
      this.news=snapshot.val();
      this.newsImageUrl=this.news.newsImage;
      this.loading=false;
    })
  }


  onChangeFile(event: EventTarget) {
    let eventObj: MSInputMethodContext = <MSInputMethodContext>event;
    let target: HTMLInputElement = <HTMLInputElement>eventObj.target;
    let files: FileList = target.files;
    this.newsImage = files[0];
    var reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.newsImageUrl = reader.result;
    }
  }

  uploadImg() {

    var self = this;
    let storageRef = firebase.storage().ref();

    var metadata = {
      contentType: 'image/jpeg/png'
    };
    const filename = Math.floor(Date.now() / 1000);
    var uploadTask = storageRef.child(`newsImages/${filename}.jpg`).put(self.newsImage, metadata);
    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
      (snapshot) => {

      }, (error) => {
        alert(error.message);
      }, () => {
        uploadTask.snapshot.ref.getDownloadURL().then( (downloadURL)=> {
          self.news.newsImage = downloadURL; 
          self.saveDataFirebase();
        });
      });
  }

  createGame(){
    if(!this.news.newsTitle || !this.news.newsDetail){
      return alert("Fill all the Fields");
    }
    if(this.newsImage){
     this.loading=true;
      this.uploadImg();
    }
    else{
     this.loading=true;
      this.saveDataFirebase();
    }
    
 }
 
 saveDataFirebase(){
   
    var updates={};
    if(this.newsId){
    updates['news/'+this.newsId]=this.news;
    firebase.database().ref().update(updates).then(()=>{
      alert("News Updated Successfully !");
      this.router.navigate(['/news']);
      this.loading=false;
    })
    }
    else{
    var postKey=firebase.database().ref('news/').push().key;
    updates['news/'+postKey]=this.news;
    firebase.database().ref().update(updates).then(()=>{
      alert("News Added Successfully !");
      this.router.navigate(['/news']);
      this.loading=false;
    })
   }
 
 }




}
