import { Component, OnInit } from '@angular/core';
import * as firebase from 'firebase';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css']
})
export class SliderComponent implements OnInit {

  public images: any;
  public slideImage: Array<any> = [];

  public sliderRef: any;

  constructor() {
    this.getSliderImages();
  }

  ngOnInit() {
    /* setTimeout(() => {
      document.getElementById("slider-next").click();
    }, 2000); */

    this.sliderRef = setInterval(() => {
      var elem = document.getElementById("slider-next");
      if(elem) {
        elem.click();
      }
    }, 6000)
  }

  ngOnDestroy() {
    clearInterval(this.sliderRef);
  }

  getSliderImages() {
    firebase.database().ref('sliderImages').once('value', (snapshot) => {
      var images = snapshot.val();
      for (var key in images) {
        this.images = images[key];
        for (var i = 0; i < this.images.length; i++) {
          if (!this.images[i].status) {
            this.slideImage.push(this.images[i]);
          }
        }
      }
    })
  }

}
