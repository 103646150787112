import { Component, OnInit } from '@angular/core';
import * as firebase from 'firebase';

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.css']
})
export class StatsComponent implements OnInit {
  activeTab: any = 'tab1';
  inputArray: Array<any> = [];
  allStats: Array<any> = [];
  data: any = {
    position: '',
    jerseyNumber: '',
    name: '',
    percentage: ''

  }
  constructor() {
    this.getStats();
    

  }
  ngOnInit() {
  }

  getStats() {
    firebase.database().ref('stats').once('value', (snapshot) => {
      var stats = snapshot.val();
      if (stats) {
        for (var key in stats) {
          var stat = stats[key];
          stat.key = key;
          this.allStats.push(stat);
        }
      }
      this.toChange(this.activeTab);
    })
  }



  toChange(tab) {
    this.activeTab = tab;
    if (this.activeTab == 'tab1') {
      this.inputArray=[];
      if (this.allStats[0]) {
        if(this.allStats[0].results){
          this.inputArray = this.allStats[0];
        }
      }
    }
    else if (this.activeTab == 'tab2') {
      this.inputArray=[];
      if(this.allStats[1]){
      if (this.allStats[1].results) {
        this.inputArray = this.allStats[1];
      }
    }
    }
    else if (this.activeTab == 'tab3') {
      this.inputArray=[];
      if(this.allStats[2]){
      if (this.allStats[2].results) {
        this.inputArray = this.allStats[2];
      }
    }

    }
    else {
      this.inputArray=[];
      if(this.allStats[3]){
      if (this.allStats[3].results) {
        this.inputArray = this.allStats[3];
      }
    }

    }

  }


  saveStats(data){
    var updates={};
    var postKey=data.key;
    if(this.activeTab=='tab1'){
      updates['stats/'+postKey+'/results']=data.results;
      updates['stats/'+postKey+'/title']=data.title;
      firebase.database().ref().update(updates).then(()=>{
        alert("Updated");
      })
    }
    else if(this.activeTab=='tab2'){
      updates['stats/'+postKey+'/results']=data.results;
      updates['stats/'+postKey+'/title']=data.title;
      firebase.database().ref().update(updates).then(()=>{
        alert("Updated");
      })
    }
    else if(this.activeTab=='tab2'){
      updates['stats/'+postKey+'/results']=data.results;
      updates['stats/'+postKey+'/title']=data.title;
      firebase.database().ref().update(updates).then(()=>{
        alert("Updated");
      })
    }
    else{
      updates['stats/'+postKey+'/results']=data.results;
      updates['stats/'+postKey+'/title']=data.title;
      firebase.database().ref().update(updates).then(()=>{
        alert("Updated");
      })
    }
   
  }


}
