import { Component, OnInit } from '@angular/core';
import * as firebase from 'firebase';

@Component({
  selector: 'app-about-gmt',
  templateUrl: './about-gmt.component.html',
  styleUrls: ['./about-gmt.component.css']
})
export class AboutGmtComponent implements OnInit {

  public data: any = {
    aboutUsDetail: ''
  };
  public loading: any = false;

  constructor() { }

  ngOnInit() {
    this.getNewsData();
  }

  getNewsData() {
    var self = this;
    self.loading = true;
    firebase.database().ref().child('abouts/aboutUsData')
      .once('value', (snapshot) => {
        self.data = snapshot.val();
        self.loading = false;
      })
      .catch((e) => {
        self.loading = false;
        alert(e.message);
      })
  }

}
