import { Component, OnInit, NgZone } from '@angular/core';
import * as firebase from 'firebase';
import { Router } from '@angular/router';

import * as moment from 'moment';
import { DataCollectorService } from '../data-collector.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  public allGames: Array<any> = [];
  public timerStringObj: any;
  public game: any;
  public days: any;
  public hours: any;
  public minutes: any;
  public seconds: any;
  public allPolls: Array<any> = [];
  public currentDate: any;
  public index: any;
  public isClient: any = false;
  public uid: any;
  public allStats: Array<any> = [];
  public staticNews: Array<any> = [];
  public allNews: Array<any> = [];
  public images: Array<any> = [];
  public slideImage: Array<any> = [];
  public allUsers: Array<any> = [];
  public mvpArray: Array<any> = [];
  public scorerArray: Array<any> = [];
  public assistArray: Array<any> = [];
  public votingArray: Array<any> = [];
  public mvpTitles = ['冠軍', '亞軍', '季軍', '第四', '第五', '第六', '第七', '第八', '第九', '第十'];
  public scorerTitles = ['金獎', '銀獎	', '銅獎', '第四', '第五', '第六', '第七', '第八', '第九', '第十'];
  public assistTitles = ['金獎', '銀獎', '銅獎', '第四', '第五', '第六', '第七', '第八', '第九', '第十'];
  public votingTitles = ['狀元', '榜眼', '探花', '第四', '第五', '第六', '第七', '第八', '第九', '第十'];


  constructor(public zone: NgZone, public router: Router, public service: DataCollectorService) {
    if (localStorage.getItem("userType") == 'client') {
      this.isClient = true;
    }
    if (localStorage.getItem('userType') == 'admin') {
      this.router.navigate(['/all-users']);
    }
    else {
      this.router.navigate(['/home']);
    }
    this.uid = localStorage.getItem('uid');
    this.currentDate = Number(new Date());
    this.currentDate = this.currentDate + 86400000;
    console.log(this.currentDate);
    this.getGames();
    this.getPolls();
    this.getStats();
    this.getAllStaticNews();
    this.getAllNews();
    this.getAllUsers();
  }

  ngOnInit() {
  }

  getAllUsers() {
    firebase.database().ref().child('users/').once('value', (snaphot) => {
      var users = snaphot.val();

      var promises = [];

      for (var key in users) {
        var user = users[key];
        promises.push(this.getUserStats(user));
        if (!user.isAdmin) {
          if (!user.status) {
            this.allUsers.push(user);
          }

        }
      }

      Promise.all(promises)
        .then(res => {
          this.allUsers.forEach(item => {
            this.mvpArray.push(item)
          });
          this.mvpArray.sort((a, b) => {
            return Number(b.mvp) - Number(a.mvp);
          });
          this.allUsers.forEach(item => {
            this.scorerArray.push(item);
          });
          this.scorerArray = this.scorerArray.sort((a, b) => {
            return Number(b.scorer) - Number(a.scorer);
          });
          this.allUsers.forEach(item => {
            this.assistArray.push(item);
          });
          this.assistArray = this.assistArray.sort((a, b) => {
            return Number(b.assist) - Number(a.assist);
          });
          this.allUsers.forEach(item => {
            this.votingArray.push(item);
          });
          this.votingArray = this.votingArray.sort((a, b) => {
            return Number(b.voting) - Number(a.voting);
          });
        });
    })
  }

  getUserStats(user) {
    return firebase.database().ref().child('userStats/' + user.uid)
      .once('value')
      .then((snapshot) => {
        var stats = snapshot.val();
        if (stats) {
          user.mvp = stats.mvp;
          user.scorer = stats.scorer;
          user.assist = stats.assist;
          user.voting = stats.voting;
        }
        return;
      });

  }

  toVideoNews() {
    this.service.newsThirdSection = this.staticNews[2];
    this.router.navigate(['/video-news'])
  }

  getAllNews() {
    firebase.database().ref('news/').once('value', (snapshot) => {
      var news = snapshot.val();
      for (var key in news) {
        var allnew = news[key];
        allnew.key = key;
        if (!allnew.status) {
          this.allNews.push(allnew);
        }
      }
      this.allNews.reverse();
    })
  }

  getAllStaticNews() {
    firebase.database().ref('staticNews').once('value', (snapshot) => {
      var news = snapshot.val();
      for (var key in news) {
        var newNews = news[key];
        newNews.key = key;
        if (newNews.youtubeUrl && !newNews.weekUpdate) {
          var youtubeUrl = newNews.youtubeUrl;
          console.log(youtubeUrl); 
          if (youtubeUrl) {
            var video_id = this.ouTubeGetID(youtubeUrl);
            
            newNews.videoImageUrl = "http://img.youtube.com/vi/" + video_id + "/0.jpg";
          }
        }
        this.staticNews.push(newNews);
      }
      console.log(this.staticNews);
    })
  }

  getData(j) {
    this.index = j;
  }

  getStats() {
    firebase.database().ref('stats').once('value', (snapshot) => {
      var stats = snapshot.val();
      if (stats) {
        for (var key in stats) {
          var stat = stats[key];
          stat.key = key;
          this.allStats.push(stat);
        }
      }
    })
  }

  ouTubeGetID(url){
    var VID_REGEX = /(?:youtube(?:-nocookie)?\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
    var ID=url.match(VID_REGEX)[1];
    return ID;
    // var ID = '';
    // url = url.replace(/(>|<)/gi,'').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    // if(url[2] !== undefined) {
    //   ID = url[2].split(/[^0-9a-z_\-]/i);
    //   ID = ID[0];
    // }
    // else {
    //   ID = url;
    // }
    //   return ID;
  }

  getPolls() {
    firebase.database().ref().child('polls/').once('value', (snapshot) => {
      var polls = snapshot.val();
      for (var key in polls) {
        var poll = polls[key];
        poll.key = key;
        if (poll.options) {
          for (var i = 0; i < poll.options.length; i++) {
            if (poll.options[i].votes) {
              if (poll.options[i].votes[this.uid]) {
                poll.voted = true;
                poll.options[i].selected = true;
              }
            } else {
              poll.options[i].votes = {};
            }
          }
        }
        poll.isExpired = (poll.timestamp) < Number(new Date());
        if (poll.voted || poll.isExpired) {
          this.calculateResults(poll);
        }

        if (poll.isMultiple) {
          poll.poleCheckedCount = 0;
        } else {
          poll.radioCheckedCount = false;
        }
        this.allPolls.push(poll);
      }
    });
  }

  calculateResults(poll) {
    poll.totalVotes = 0;
    poll.options.forEach(option => {
      option.voteCount = Object.keys(option.votes).length;
      poll.totalVotes += option.voteCount;
    });

    poll.options.sort((a, b) => b.voteCount - a.voteCount);
  }

  toSaveData(poll) {
    var updates = {};
    var mainVotes = [];

    if (poll.votes) {
      mainVotes = poll.votes;
    }

    if (poll.isMultiple) {
      mainVotes.push(localStorage.getItem('uid'));
      updates['polls/' + poll.key + '/options'] = poll.options;
      updates['polls/' + poll.key + '/votes'] = mainVotes;
      firebase.database().ref().update(updates).then(() => {
        poll.voted = true;
        alert("Voted Successfully !");
      })

      this.calculateResults(poll);
    }
    else {
      mainVotes.push(localStorage.getItem('uid'));
      poll.options[this.index].votes[this.uid] = true;
      updates['polls/' + poll.key + '/options/' + this.index + '/votes/' + this.uid] = true;
      updates['polls/' + poll.key + '/votes'] = mainVotes;
      firebase.database().ref().update(updates).then(() => {
        poll.voted = true;
        alert("Voted Successfully !");
      })

      this.calculateResults(poll);
    }
  }

  getGames() {
    firebase.database().ref().child('games/').once('value', (snapshot) => {
      var games = snapshot.val();
      for (var key in games) {
        var game = games[key];
        game.key = key;
        //var dateObj = Number(new Date(game.gameDate + ' ' + game.gameTime));
        var dateObj = Number(moment(game.gameDate + ' ' + game.gameTime, "YYYY-MM-DD HH:mm").toDate());
        var date = Number(new Date());
        if (dateObj > date) {
          game.timeDiffernce = dateObj - date;
          game.dateObj = dateObj;
          this.allGames.push(game);
        }
      }
      this.sortGame();
    })

  }

  sortGame() {
    this.allGames.sort((a, b) => {
      return Number(a.timeDiffernce) - Number(b.timeDiffernce);
    });
    this.game = this.allGames[0];
    this.timerFunction(this.allGames[0].dateObj);

  }

  timerFunction(edate) {
    var self = this;
    var x = setInterval(function () {
      var now = new Date().getTime();
      var distance = edate - now;
      self.zone.run(() => {
        self.days = Math.floor(distance / (1000 * 60 * 60 * 24));
        self.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        self.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        self.seconds = Math.floor((distance % (1000 * 60)) / 1000);
        // self.timerStringObj = days + "d " + hours + "h "
        // + minutes + "m " + seconds + "s ";
      })
      if (distance < 0) {
        clearInterval(x);
        self.timerStringObj = "Started";
      }
    }, 1000);
  }

  onCheckChange(event: any, poll) {
    if (event) {
      poll.poleCheckedCount++;
    } else {
      poll.poleCheckedCount--;
    }
  }

  onRadioChange(event: any, poll) {
    poll.radioCheckedCount = true;
  }

}
