import { Component, OnInit, NgZone } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as firebase from 'firebase';
import * as moment from 'moment';
import { not } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-game-detail',
  templateUrl: './game-detail.component.html',
  styleUrls: ['./game-detail.component.css']
})
export class GameDetailComponent implements OnInit {

  public gameId: any;
  public loading: any = false;
  public game: any;
  public isClient:any;
  tempGame: any;

  constructor(public route: ActivatedRoute, public zone: NgZone) {
    if (localStorage.getItem("userType") == 'client') {
      this.isClient = true;
    }
    this.route.params.subscribe((params) => {
      this.gameId = params['id'];
      this.getGameData();
    })
  }

  ngOnInit() {
  }

  getGameData() {
    this.loading = true;
    firebase.database().ref().child('games/' + this.gameId).once('value', (snapshot) => {
      this.game = snapshot.val();
      this.game.key=this.gameId;
      this.game.teamData=[];
      this.game.notJoinData=[];
      if (this.game.team) {
        if (this.game.team.includes(localStorage.getItem('uid'))) {
          this.game.join = true;
        }
        for (var i = 0; i < this.game.team.length; i++) {
          this.getUserInfo(this.game, i);
        }

      }
      if (this.game.notJoin) {
        for (var j = 0; j < this.game.notJoin.length; j++) {
          this.getUserInfoNotJoin(this.game, j);
        }
      }
      console.log(this.game);
      var dateObj = moment(this.game.gameDate + ' ' + this.game.gameTime, "YYYY-MM-DD HH:mm").toDate();
      this.game.dateObj = dateObj;
      var dateTime = Number(dateObj);
      var date = Number(new Date());
      if (86400000 < (dateTime - date)) {
        this.game.update = true;
      }
      else{ 
        if(this.game.team){
        if(this.game.team.includes(localStorage.getItem('uid'))){
          debugger;
          console.log(dateObj.getDate());
          console.log(new Date().getDate());
          debugger;
          if(dateObj.getDate()>new Date().getDate()){
            this.game.update=true;
          }
        }
        }
        if(this.game.notJoin){
        if(this.game.notJoin.includes(localStorage.getItem('uid'))){
          if(dateObj.getDate()>new Date().getDate()){
            this.game.update=true;
          }
        }
      }
    }
      this.loading = false;
    })
  }

  getUserInfo(game, i) {
    firebase.database().ref('users/' + this.game.team[i]).once('value', (snapshot) => {
      var userData = snapshot.val();
      if(userData){
      this.zone.run(() => {
        if (userData.status != 'restrict') { game.teamData.push(userData); }
      })
    }
    else{
      game.team.splice(i,1);
      game.teamData.splice(i,1);
    }
    })
    
  }

  getUserInfoNotJoin(game, j) {
    firebase.database().ref('users/' + this.game.notJoin[j]).once('value', (snapshot) => {
      var userData = snapshot.val();
      if(userData){
      this.zone.run(() => {
        if (userData.status != 'restrict') { game.notJoinData.push(userData); }
      })
    }
    else{
      game.notJoin.splice(j,1);
      game.notJoinData.splice(j,1);
    }
    })
   
  }


  joinGameExtraStep() {
    if (this.game.join) {
      this.joinGame(this.game);
    } else {
      var self = this;
      self.zone.run(() => {
        self.game.join = false;
        self.tempGame = self.game;
      });
      let modal: HTMLElement = document.getElementById("modalBtn") as HTMLElement;
      modal.click();
    }
  }

  confirmNotJoin() {
    this.zone.run(() => {
      this.tempGame.join = false;
      this.joinGame(this.tempGame);
    });
  }

  reverseToggle() {
    this.tempGame.join = true;
  }

  joinGame(game) {
    var team = [];
    var notJoin = [];
    var updates = {};
    if (game.team) {
      team = game.team;
    }
    if (game.notJoin) {
      notJoin = game.notJoin;
    }
    if (!game.join) {
      var index = team.indexOf(localStorage.getItem('uid'));
      if (index !== -1) {
        team.splice(index, 1);
      }
      if(!notJoin.includes(localStorage.getItem('uid'))){
        notJoin.push(localStorage.getItem('uid'));
      }
      updates['games/' + game.key + '/team'] = team;
      updates['games/' + game.key + '/notJoin'] = notJoin;
      firebase.database().ref().update(updates).then(() => {
      });
    } else {
      if(!team.includes(localStorage.getItem('uid'))){
        team.push(localStorage.getItem('uid'));
      }
      var index = notJoin.indexOf(localStorage.getItem('uid'));
      if (index !== -1) {
        notJoin.splice(index, 1);
      }
      updates['games/' + game.key + '/team'] = team;
      updates['games/' + game.key + '/notJoin'] = notJoin;
      firebase.database().ref().update(updates).then(() => {
      });
    }
  }

  dismissModal(e: any, modalId, closingId) {
  
    if (e.srcElement.id) {
      var el = document.getElementById(closingId);
      if (el) {
        el.click();
      }
    }
  }


}
