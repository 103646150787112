import { Component, OnInit, NgZone } from '@angular/core';
import * as firebase from 'firebase';

@Component({
  selector: 'app-all-stats',
  templateUrl: './all-stats.component.html',
  styleUrls: ['./all-stats.component.css']
})
export class AllStatsComponent implements OnInit {

  public loading:any=false;
  public allUsers:Array<any>=[];
  public reverseSort: any = true;
  public sortColoumn: any = 'jerseyNumber';


  constructor(public zone:NgZone) { }

  ngOnInit() {
    this.getAllUsers();
  }
  sortData(coloumn) {
    this.reverseSort = (this.sortColoumn == coloumn) ? !this.reverseSort : false;
    this.sortColoumn = coloumn;
  }

  getSortClass(coloumn) {
    debugger;
    if (this.sortColoumn == coloumn) {
      return this.reverseSort ? 'arrow-down' : 'arrow-up'
    }

  }

  getAllUsers() {
    this.loading = true;
    firebase.database().ref().child('users/').once('value', (snaphot) => {
      var users = snaphot.val();
      for (var key in users) {
        var user = users[key];
        user.key = key;
        this.getUserStats(user);
        if (!user.isAdmin) {
          this.allUsers.push(user);
        }
        this.loading = false;
      }
      this.sortData(this.sortColoumn)
     //this.sortViaJerseyNumber();
    })
  }

  sortViaJerseyNumber(){
    this.allUsers.sort((a,b)=>{
      return a.jerseyNumber-b.jerseyNumber;
    })
  }

  getUserStats(user){
    firebase.database().ref().child('userStats/'+user.uid)
    .once('value',(snapshot)=>{
      var stats=snapshot.val();
      if(stats){
      this.zone.run(()=>{
        user.mvp=stats.mvp;
        user.scorer=stats.scorer;
        user.assist=stats.assist;
        user.voting=stats.voting;
      })
      }
    })

  }

  toSaveStats(user,i){
    var updates={};
    updates['userStats/'+user.uid+'/mvp']=Number(user.mvp) || 0;
    updates['userStats/'+user.uid+'/scorer']=Number(user.scorer)||0;
    updates['userStats/'+user.uid+'/assist']=Number(user.assist) || 0;
    updates['userStats/'+user.uid+'/voting']=Number(user.voting)|| 0;
    firebase.database().ref().update(updates).then(()=>{
      alert("Data Saved Successfully!");
    })


  }


}
