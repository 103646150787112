import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as firebase from 'firebase';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { DataCollectorService } from '../data-collector.service';


@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.css']
})
export class CreateUserComponent implements OnInit {

  public imageChangedEvent: any = '';
  public croppedImage: any = '';
  public imageBase64: any = '';
  public isEdit = false;

  public uid: any;
  public userImage: any;
  public imageUrl: any;
  public imageUrlTemp: any;
  public user: any = {
    timestamp: Number(new Date())
  }
  loading: any = false;
  public allUsers: Array<any> = [];


  constructor(public router: Router,
    public dataCollector: DataCollectorService) {
    if (this.dataCollector.isEdit) {
      this.dataCollector.isEdit = false;
      this.isEdit = true;
      this.user.phone = this.dataCollector.userEditObj.phone;
      this.user.jerseyNumber = this.dataCollector.userEditObj.jerseyNumber;
      this.user.name = this.dataCollector.userEditObj.name;
      this.user.nickName = this.dataCollector.userEditObj.nickName;
      this.user.position = this.dataCollector.userEditObj.position;
      this.user.remark = this.dataCollector.userEditObj.remark;
      this.uid = this.dataCollector.userEditObj.uid;

      if (this.dataCollector.userEditObj.profileUrl) {
        this.imageUrlTemp = this.dataCollector.userEditObj.profileUrl;
      }
    }
  }

  ngOnInit() {
  }

  onChangeFile(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.imageUrlTemp = undefined;
    this.croppedImage = event.base64;
    this.userImage = this.dataURLtoFile(event.base64, 'sample file');
  }


  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  addUser() {
    if (!this.user.name || !this.user.phone || !this.user.nickName || !this.user.password || !this.user.confirmPassword || !this.user.jerseyNumber || !this.user.position || !this.user.remark) {
      return alert("Fill all Fields!");
    }
    if (this.user.password != this.user.confirmPassword) {
      return alert('Password Mismatched !');
    }
    this.loading = true;
    var addUser = firebase.functions().httpsCallable("createUser");
    addUser({
      name: this.user.name,
      email: `gmt${this.user.phone}@email.com`,
      password: this.user.password,

    }).then((result) => {
      if (result.data.status == "200") {
        this.uid = result.data.data.uid;
        if (this.uid) {
          if (this.userImage) {
            this.uploadUserImg(this.uid);
          }
          else {
            this.saveDataFirebase(this.uid);
          }
        }
      }
      else {
        alert(result.data.message);
      }
    }, (error) => {
      alert(error);
    });
  }

  uploadUserImg(uid) {
    var self = this;
    let storageRef = firebase.storage().ref();

    var metadata = {
      contentType: 'image/jpeg/png'
    };
    const filename = Math.floor(Date.now() / 1000);
    var uploadTask = storageRef.child(`profileImages/${filename}.jpg`).put(self.userImage, metadata);
    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
      (snapshot) => {
      }, (error) => {
        alert(error.message);
      }, () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          self.user.profileUrl = downloadURL;
          self.saveDataFirebase(uid);
        });
      });
  }

  saveDataFirebase(uid) {
    var self = this;
    var id = document.getElementById('newUser');
    this.user.uid = uid;
    delete this.user.password;
    delete this.user.confirmPassword;
    var updates = {};
    updates['/users/' + uid] = this.user;
    firebase.database().ref().update(updates).then(() => {
      alert("Account Created Successfully");
      this.loading = false;
      this.router.navigate(['/all-users']);
      this.user = {};
      this.imageUrl = "";
    });
  }

  editUser() {
    if (!this.user.name || !this.user.phone || !this.user.nickName || !this.user.jerseyNumber || !this.user.position || !this.user.remark) {
      return alert("Fill all Fields!");
    }
    this.loading = true;
    if (this.userImage) {
       this.uploadUserImgEdit(this.uid);
    }
    else {
      this.saveDataFirebaseEdit(this.uid);
    }
  }

  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }



  //Edit Helper Functions are here...

  uploadUserImgEdit(uid) {
    var self = this;
    let storageRef = firebase.storage().ref();

    var metadata = {
      contentType: 'image/jpeg/png'
    };
    const filename = Math.floor(Date.now() / 1000);
    var uploadTask = storageRef.child(`profileImages/${filename}.jpg`).put(self.userImage, metadata);
    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
      (snapshot) => {
      }, (error) => {
        alert(error.message);
      }, () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          self.user.profileUrl = downloadURL;
           self.saveDataFirebaseEdit(uid);
        });
      });
  }

  saveDataFirebaseEdit(uid) {
    var self = this;
    var updates = {};

    updates['/users/' + uid + "/phone"] = self.user.phone;
    updates['/users/' + uid + "/jerseyNumber"] = self.user.jerseyNumber;
    updates['/users/' + uid + "/name"] = self.user.name;
    updates['/users/' + uid + "/nickName"] = self.user.nickName;
    updates['/users/' + uid + "/position"] = self.user.position;
    updates['/users/' + uid + "/remark"] = self.user.remark;

    if (self.user.profileUrl) {
      updates['/users/' + uid + "/profileUrl"] = self.user.profileUrl;
    }

    firebase.database().ref().update(updates).then(() => {
      alert("User Updated Successfully");
      this.loading = false;
      this.router.navigate(['/all-users']);
    });
  }

}
