import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataCollectorService {

  public userEditObj: any;
  public isEdit = false;
  public newsThirdSection:any;

  constructor() {

  }
}
