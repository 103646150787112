import { Component, OnInit } from '@angular/core';
import * as firebase from 'firebase';
import { Router } from '@angular/router';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  public phone:any;
  public password:any;
  public uid:any;
  public loading:any=false;
  public isClient:any=false;
  public profileUrl:any;
  public user:any={};
  public imageChangedEvent: any = '';
  public croppedImage: any = '';
  imageUrlTemp: any;
  userImage: any;

  constructor(public router:Router) { 
    if(localStorage.getItem("userType")=='client'){
     this.isClient=true;
    }
    if(localStorage.getItem('profileUrl')){
      this.profileUrl=localStorage.getItem('profileUrl');
    }
  }

  ngOnInit() {
  }

  getUserInfo(){
    firebase.database().ref().child('users' + '/' + localStorage.getItem('uid')).once('value', (snapshot) => {
      var user = snapshot.val();
      if (user) {
        this.user=user;
        if (this.user.profileUrl) {
          this.imageUrlTemp = this.user.profileUrl ;
        }
        else{
          this.imageUrlTemp="/assets/img/user2.png";
        }
      }
    })
  }

  toLogin(){

    debugger;
    this.loading=true;

    var email = this.phone;

    if(email !== "admin@email.com") {
      email = `gmt${this.phone}@email.com`;
    }

    firebase.auth().signInWithEmailAndPassword(email, this.password).then((user) => {
      if (user) {
        this.uid = user.user.uid;
        this.getUserData();
      }
    })
      .catch((error) => {
        var errorMessage = error.message;
        this.loading=false;
        alert(errorMessage);
      });
  }

  toLogout(){
    localStorage.clear();
    window.location.reload();
  }

  getUserData(){
    var id=document.getElementById('myModal');
    firebase.database().ref().child('users' + '/' + this.uid).once('value', (snapshot) => {
      var user = snapshot.val();
      if (user) {
        if(!user.status){
        localStorage.setItem('userLoggedIn', 'true');
        localStorage.setItem('uid', user.uid);
        if (user.isAdmin) {
          id.click();
          this.loading=false;
          localStorage.setItem('userType', 'admin');
          this.router.navigate(['/all-users'])
        }
        else {
          id.click();
          this.loading=false;
          localStorage.setItem('userType', 'client');
          if(user.profileUrl){
            localStorage.setItem('profileUrl',user.profileUrl);
          }
          window.location.reload();
        }
      }
      else{
        id.click();
        this.router.navigate(['/home']);
        this.loading=false;
        alert("You are blocked by Admin.For any query please Contact Admin!")
      }
      }

    })
      .catch((e) => {
        alert(e.message);
      })
  }
  editUser(){
    if (this.userImage) {
      this.uploadUserImgEdit();
   }
   else {
     this.saveDataFirebaseEdit();
   }
  }

  onChangeFile(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.imageUrlTemp = undefined;
    this.croppedImage = event.base64;
    this.userImage = this.dataURLtoFile(event.base64, 'sample file');
  }

  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }


  uploadUserImgEdit() {
    var self = this;
    let storageRef = firebase.storage().ref();

    var metadata = {
      contentType: 'image/jpeg/png'
    };
    const filename = Math.floor(Date.now() / 1000);
    var uploadTask = storageRef.child(`profileImages/${filename}.jpg`).put(self.userImage, metadata);
    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
      (snapshot) => {
      }, (error) => {
        alert(error.message);
      }, () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          self.user.profileUrl = downloadURL;
           self.saveDataFirebaseEdit();
        });
      });
  }


  saveDataFirebaseEdit(){
    var updates={};
    var id=document.getElementById('profileModal');
    updates['users/'+this.user.uid]=this.user;
    firebase.database().ref().update(updates).then(()=>{
      id.click();
      this.userImage={};
      this.croppedImage="";
      this.imageChangedEvent="";
      if(this.user.profileUrl){
        this.profileUrl=this.user.profileUrl;
        localStorage.setItem('profileUrl',this.user.profileUrl);
      }
      alert("User Updated Successfully!");
    })
  }
}
