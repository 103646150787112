import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as firebase from 'firebase';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-edit-poll',
  templateUrl: './edit-poll.component.html',
  styleUrls: ['./edit-poll.component.css']
})
export class EditPollComponent implements OnInit {

  public sizeVariants:Array<any>=[];
  pollId: any;
  public poll:any={};

  constructor(public route:ActivatedRoute, public datePipe:DatePipe, public router:Router) {
   this.route.params.subscribe((params)=>{
   this.pollId=params['id'];
   this.getPollData();
   })
   }

  ngOnInit() {
  }

  getPollData(){
    firebase.database().ref().child('polls/'+this.pollId).once('value',(snapshot)=>{
      this.poll=snapshot.val();
      this.poll.timestamp=this.datePipe.transform(this.poll.timestamp,'yyyy-MM-dd')
      console.log(this.poll);
    })
  }

  increseSize() {
    var data={
      title:""
    }
    this.poll.options.push(data);
  }

  decreaseSize(index) {
    this.poll.options.splice(index, 1);
  }

  toUpdatePoll(){
    this.poll.timestamp=Number(new Date(this.poll.timestamp));
    var updates={};
    updates['polls/'+this.pollId]=this.poll;
    firebase.database().ref().update(updates).then(()=>{
      alert("Poll Updated!");
      this.router.navigate(['/polls']);
    })
  }

}
